@font-face {
  font-family: 'Nunito';
  src: url('assets/Nunito.ttf') format('truetype');
}

@font-face {
  font-family: 'SourceSansProLight';
  src: url('assets/SourceSansPro-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'SourceSansProSemiBold';
  src: url('assets/SourceSansPro-SemiBold.ttf') format('truetype');
}
